import { animateFade, animateFadeUp } from "../../../assets/src/js/includes/animations";

const blocks = document.querySelectorAll(
    `.wp-block-blufish-form`
);

if (blocks.length > 0) {
    blocks.forEach((block) => {
        const image = block.querySelector(`.js-image`);
        const form = block.querySelector(`.js-form-wrap`);

        animateFade(image);
        animateFadeUp(form);
    });
}